import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import ReactMarkdown from "react-markdown"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"

import indexStyles from "../pages/index.module.scss"

const PraxisPage = () => {

    const data = useStaticQuery(graphql`
        query {
            heroBackground: file(relativePath: { regex: "/rainbow/" }) {
                childImageSharp {
                    fluid
                    {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            kowatsch: file(relativePath: { regex: "/kowatsch/" }) {
                childImageSharp {
                    fluid
                    {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            strapiPraxis {
                description
            }
        }
    `)

    return (
        <Layout>
            <SEO title="Praxis" />
            {/* <BackgroundImage
                className={`backgroundImage`}
                fluid={data.heroBackground.childImageSharp.fluid}
            >
                <Container className={`hero mainMid`} />
            </BackgroundImage>

            <Container className={`heroTextBox`}>
                <h1 className={`smallH1`}>Unsere Praxis</h1>
                <span className={`headline`}>Hier steht ein Untertitel</span>
            </Container> */}

            <Container className={`py-0 py-md-5 my-5`}>
                <ReactMarkdown source={data.strapiPraxis.description} escapeHtml={false} />
                
            </Container>
        </Layout>
    )
}

export default PraxisPage
